<template>
  <div
    class="rounded shadow-hard bg-white overflow-hidden flex flex-col"
  >
    <span
      class="bg-primary h-full w-full
        min-h-[10px] min-w-[10px] laptop:min-h-[15px] laptop:min-w-[15px]"
    />

    <div
      class="relative"
    >
      <NuxtPicture
        :src="props.image.src"
        :alt="props.image.alt"
        :title="props.image.title"
        loading="lazy"
        class="absolute inset-0"
      />

      <div
        class="relative z-10 mx-8 my-12 flex flex-col gap-4"
      >
        <h2
          class="text-white text-shadow-light text-center"
        >
          {{ props.heading }}
        </h2>

        <p
          class="text-white text-shadow-light text-center laptop:mx-16"
        >
          {{ props.paragraph }}
        </p>

        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Image } from "~/utils/types";

const props = defineProps<{
  image: Image
  heading: string
  paragraph: string
}>()
</script>

<style scoped lang="postcss">
:deep(img) {
  @apply w-full h-full object-cover object-top
}
</style>
